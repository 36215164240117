<template>
  <a-form-model
    ref="form"
    :rules="rules"
    :label-col="{ span: 6 }"
    :wrapper-col="{ span: 15 }"
    :model="form"
  >
    <a-form-model-item label="名称:" prop="name">
      <a-input v-model="form.name" type="string" placeholder="请输入" />
    </a-form-model-item>
    <a-form-model-item label="手机号:" prop="phone">
      <a-input
        :disabled="form.userId"
        v-model="form.phone"
        type="string"
        placeholder="请输入"
      />
    </a-form-model-item>

    <a-form-model-item label="所属团队" prop="channelId">
      <a-select
        v-model="form.channelId"
        placeholder="请选择"
      >
        <a-select-option
          :value="item.id"
          v-for="(item, index) in teamList"
          :key="index"
        >
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item label="邀请方手机号:">
      <a-input v-model="form.invitePhone" type="string" placeholder="请输入" />
    </a-form-model-item>

    <a-form-model-item
      :colon="false"
      label=" "
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-space>
        <a-button @click="cancel"> 取消 </a-button>
        <a-button @click="determine" type="primary">
          {{ form.userId ? "编辑" : "添加" }}</a-button
        >
      </a-space>
    </a-form-model-item>

    <br />
  </a-form-model>
</template>

<script>
export default {
  data () {
    return {
      btCount: 0,
      teamList: [], // 团队列表
      isDetermineLoading: false, // 是否显示确定loading
      form: {
        name: '',
        channelId: '',
        phone: '',
        invitePhone: ''
      },
      rules: {
        name: [
          {
            required: true,
            message: '姓名不能为空',
            trigger: 'change'
          }
        ],
        channelId: [
          {
            required: true,
            message: '所属于团队不能为空',
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: true,
            message: '手机号不能为空',
            trigger: 'change'
          },
          {
            pattern: this.$consts.phoneReg,
            message: '请输入正确的手机号'
          }
        ]
      }
    }
  },
  created () {},
  mounted () {},
  computed: {},
  methods: {
    setTreeFatherDisabled (alist, lever) {
      lever ? lever++ : (lever = 1)
      alist.map((item) => {
        if (lever === 3) {
          item.disabled = true
        } else {
          item.disabled = false
          if (item.children) {
            item.children = this.setTreeFatherDisabled(item.children, lever)
          }
        }
      })
      return alist
    },
    resetForm () {
      this.form = {
        name: '',
        channelId: '',
        phone: '',
        invitePhone: ''
      }
    },
    // 设置表单
    async setForm (form = {}) {
      this.btCount = 0
      this.queryTreeData()
      this.resetForm()
      if (form.id) {
        this.form = {
          idPerent: form.idPerent,
          id: form.id,
          name: form.name,
          sort: form.sort,
          iconUrl: form.iconUrl
        }
      } else {
        this.form.idPerent = form.idPerent
      }
    },
    cancel () {
      this.$refs.form.resetFields()
      this.$refs.form.clearValidate()
      this.form = {
        name: '',
        channelId: '',
        phone: '',
        invitePhone: ''
      }
      this.$emit('cancel')
    },
    async init (
      item = {
        userId: undefined,
        name: undefined,
        phone: undefined,
        channelId: undefined,
        invitePhone: undefined
      }
    ) {
      // 查找团队列表
      const { records } = await this.$store.dispatch('http', {
        api: 'teamList',
        params: {
          pageSize: 1000
        }
      })
      this.teamList = records

      const { userId, name, phone, channelId, invitePhone } = item
      this.form = {
        userId,
        name,
        phone,
        channelId,
        invitePhone
      }

      console.log(records, 'teamlist')
      console.log(item)
    },
    // 提交保存
    async determine (e) {
      if (!this.btCount) {
        this.goComfirm()
        this.btCount++
      }
      setTimeout(() => {
        this.btCount = 0
      }, 1000)
    },
    async goComfirm (e) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const params = this.$utils.clone(this.form)
          let api = 'addDistributor'
          if (params.userId) {
            api = 'editDistributor'
            delete params.userId
          }
          await this.$store.dispatch('http', {
            api,
            params,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', {
            message:
              (api === 'editDistributor' ? '编辑' : '新增') + '分销员成功'
          })
          this.$emit('ok')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
