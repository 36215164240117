<template>
    <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
        <a-button class="add-bt" type="primary"  @click="add()">新增</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item label="手机号:">
        <a-input v-model="form.phone" placeholder="请输入手机号" />
      </a-form-item>

      <a-row class="al-form__btn"
             type="flex"
             justify="end">
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-row>
    </a-form>

    <a-table :data-source="list"
             bordered
             :rowKey="record => record.userId"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination">
         <div slot="action" slot-scope="text,record">
             <a @click="edit(record)" ><a-spin size="small" v-if="isLoading" />编辑</a>
        </div>
    </a-table>

    <a-modal
        :title="modalTitle"
      :footer="null"
      :visible="visible"
      @ok="handleOk"
       @cancel="handleCancel"
    >
        <EditDistributor  @ok="handleOk" @cancel="handleCancel" ref="modalRef"></EditDistributor>
    </a-modal>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import EditDistributor from './addEditDistributor.vue'
export default {
  components: { Breadcrumb, EditDistributor },
  data () {
    return {
      breadList: [
        {
          name: '分销员管理',
          url: ''
        },
        {
          name: '分销员列表',
          url: ''
        }
      ],
      modalTitle: '添加分销员',
      isLoading: false, // 编辑状态
      visible: false, // modal
      isQueryLoading: false,
      form: {
        phone: ''
      },
      columns: [
        { title: '分销员', width: 100, key: 'name', dataIndex: 'name' },
        { title: '手机号', width: 150, key: 'phone', dataIndex: 'phone' },
        { title: '所属团队', width: 120, key: 'channelName', dataIndex: 'channelName' },
        { title: '等级', width: 100, key: 'gradeName', dataIndex: 'gradeName' },
        { title: '邀请方', width: 120, key: 'inviteName', dataIndex: 'inviteName' },
        { title: '累计销售额', width: 120, key: 'orderAmount', dataIndex: 'orderAmount' },
        { title: '累计佣金', width: 120, key: 'award', dataIndex: 'award' },
        { title: '累计邀请数', width: 120, key: 'inviteCount', dataIndex: 'inviteCount' },
        { title: '现有用户数', width: 120, key: 'userCount', dataIndex: 'userCount' },
        { title: '加入时间', key: 'gmtGrade', dataIndex: 'gmtGrade' },
        { title: '操作', width: 80, fixed: 'right', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  mounted () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
    add () {
      this.modalTitle = '添加分销员'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init()
      })
    },
    edit (record) {
      this.modalTitle = '编辑分销员'
      this.visible = true
      this.$nextTick(() => {
        this.$refs.modalRef.init(record)
      })
    },
    handleOk (e) {
      setTimeout(() => {
        this.visible = false
        this.visibleStack = false
        this.visibleSale = false
      })
      this.queryList()
    },
    handleCancel (e) {
      console.log('Clicked cancel button')
      this.$refs.modalRef.$refs.form.clearValidate()
      this.visible = false
    },
    // 重置表单
    resetForm () {
      this.form = {
        name: ''
      }
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'distributorsList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      console.log(records, '分销员列表')
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log('分页、排序、筛选变化时触发', val)
      this.pagination.pageSize = val.pageSize
      this.pagination.current = val.current
      this.loadList()
    }
  }
}
</script>

<style lang="scss" module>
.container {
  width: auto;
}
</style>
